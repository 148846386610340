import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Alerts } from '../../shared/enums/alerts';
import { Patterns } from '../../shared/enums/patterns';
import { SharedService } from '../../shared/shared.service';
import { GeofenceService } from '../geofence.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddGeofenceComponent } from '../add-geofence/add-geofence.component';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, merge, startWith } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from "rxjs/operators";
import { UpdateGeofenceComponent } from '../update-geofence/update-geofence.component';
import { ShowResultComponent } from '../show-result/show-result.component';
import { GeofenceStatus } from 'src/app/shared/enums/geofence-status';

declare let google: any;

@Component({
    selector: 'app-list-geofence',
    templateUrl: './list-geofence.component.html',
    styleUrls: ['./list-geofence.component.scss']
})
export class ListGeofenceComponent implements OnInit {

    isLoading: boolean = false;
    dialogRef: MatDialogRef<unknown, any>;
    showDetails: boolean = true;
    geofenceFileFC = new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.SCHEMA_FILE)]);
    geofenceFile: File;
    geofenceFileName: string;
    
    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapType: any = google.maps.MapTypeId.ROADMAP;
    mapZoom: number = 5;
    bonds: any = new google.maps.LatLngBounds();
    
    selectedGeofences: Array<any> = [];
    selectedGeofence: any;
    selectedGeofenceId: string;
    geofenceList: Array<any> = [];
    geofenceListVersion: string;
    displayedColumns: string[] = ['select', 'geofenceId', 'orgId', 'status', 'options'];
    dataSource = new MatTableDataSource<any>(this.geofenceList);
    selection = new SelectionModel<any>(true, []);
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    sortDirection = "asc";
    pageSizeOptions: Array<number> = [10, 20];
    searchFilter = new FormControl();
    searchFilterValue: string;
    totalCount : number = 0;
    listStatusFilter: string = GeofenceStatus.PENDING;
    statusFilters: Array<string> = [GeofenceStatus.PENDING, GeofenceStatus.VERIFIED];


    geofenceViewOptions: any = [{name: "exactView", displayName: "Exact View", checked: true}, 
                                {name: "expandedView", displayName: "Expanded View", checked: true}];

    readonly GEOFENCE_FILE: string = 'geofenceFileFC';
    readonly PENDING_STATUS: string = GeofenceStatus.PENDING;
    readonly VERIFIED_STATUS: string = GeofenceStatus.VERIFIED;
    readonly STAGING_STATUS: string = GeofenceStatus.STAGING;

    constructor(private dialog: MatDialog,
                private cdref: ChangeDetectorRef,
                private sharedService: SharedService,
                private geofenceService: GeofenceService) { 

    }

    ngOnInit(): void {
        this.mapCenter =  {         // current coordinates and passing into
            lat: 38.0,              // current location
            lng: -97.0,
        };
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.sort.disableClear = true;
        this.sort.active = "geofenceId"
        this.paginator.pageSize = 10;
        this.getGeofenceList();
    }

    getGeofenceList() {
        merge(this.searchFilter.valueChanges, this.sort.sortChange, this.paginator.page)
            .pipe(
                debounceTime(500),
                startWith({}),
                switchMap(() => {
                    Swal.fire({
                        title: 'Please Wait...',
                        text: 'Fetching ' + this.listStatusFilter.toLowerCase() + ' geofence list',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                    this.isLoading = true;
                    if (this.searchFilter.value !== null && this.searchFilterValue !== this.searchFilter.value) {
                        this.paginator.pageIndex = 0;
                    }
                    this.searchFilterValue = this.searchFilter.value ? this.searchFilter.value : '';
                    return this.getGeofences(
                        this.searchFilterValue,
                        this.sort.active,
                        this.sort.direction,
                        this.paginator.pageIndex,
                        this.paginator.pageSize
                    ).pipe(catchError(() => new Observable(null)));
                }),
                map((data) => {
                    Swal.close();
                    this.isLoading = false;
                    if (data === null) {
                        this.totalCount = 0;
                        this.geofenceListVersion = undefined;
                        this.sharedService.showAlert(Alerts.ERROR, 'Failed to fetch ' + this.listStatusFilter.toLowerCase() + ' geofence list');
                        return [];
                    }
                    this.totalCount = data.data.totalElements;
                    this.geofenceListVersion = data.version;
                    return data.data.content;
                })
            )
            .subscribe((data) => {
                this.geofenceList = data;
                this.selectedGeofences = [];
                this.setGeofenceList(this.geofenceList);
            });
    }

    getGeofences(searchFilterValue, sortActive, sortDirection, pageIndex, pageSize): Observable<any> {
        if (sortDirection !== "" && this.sortDirection !== sortDirection) {
            this.sortDirection = sortDirection;
        }
        return this.geofenceService.getGeofenceList(this.listStatusFilter,
                                                    this.statusFilters,
                                                    searchFilterValue, 
                                                    sortActive, 
                                                    sortDirection, 
                                                    pageIndex, 
                                                    pageSize);
    }

    setGeofenceList(geofences: Array<any>) {
        this.pageSizeOptions = [10, 20];
        if (this.totalCount >= 30) {
            this.pageSizeOptions.push(30);
        } 
        if (this.totalCount >= 40) {
            this.pageSizeOptions.push(40);
        }
        if (this.totalCount >= 50) {
            this.pageSizeOptions.push(50);
        }
        if (this.totalCount >= 100) {
            this.pageSizeOptions.push(100);
        }
        if (this.totalCount >= 500) {
            this.pageSizeOptions.push(500);
        } else {
            this.pageSizeOptions.push(this.totalCount);
        }
        this.paginator.pageSizeOptions = this.pageSizeOptions;

        this.dataSource = new MatTableDataSource<any>(geofences);
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy() {
         this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('geofenceViewOptions'));
    }

    centerMap() {
        if(this.bonds) {
            this.map.fitBounds(this.bonds);
        } else {
            this.map.setCenter(this.mapCenter);
        }
    }

    changeViewOption(option) {
        let count: number = 0;
        this.geofenceViewOptions.forEach( view => {
            if(view.checked) {
                ++count;
            }
        });
        if(count === 0) {
            option.checked = true;
        } else {
            this.displayGeofenceOnMap();
        }
    }

    styleFunc(strokeColor: string) {
        return ({
            clickable: false,
            fillColor: "#A0A070",
            fillOpacity: 0.12,
            strokeColor: strokeColor,
            strokeWeight: 1
        });
    }

    displayGeofenceOnMap() {
        this.clearMapData();
        this.geofenceViewOptions.forEach( view => {
            if(view.checked) {
                let geometry = this.getGeometry(view.name, this.selectedGeofence);
                let geoJsonObjects: Object = {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "properties": {
                                "strokeColor": (view.name === "exactView") ? "black" : "red",
                                "view": view.name
                            },
                            "geometry": geometry
                        }
                    ]
                };
                this.map.data.addGeoJson(geoJsonObjects);
                this.map.data.setStyle((feature) => {
                    let viewName = feature.getProperty("view");
                    let strokeColor = viewName === "exactView" ? "black" : "red";
                    return this.styleFunc(strokeColor);
                });
                this.adjustBounds(geometry);
            }
        });
    }

    getGeometry(view, geofence) {
        let geometry;
        switch(view) {
            case 'exactView':
                geometry = geofence.exact_polygon;
                break;
            case 'expandedView':
                geometry = geofence.expanded_polygon;
                break;
            default:
                geometry = geofence.exact_polygon;
                break;
        }
        return geometry;
    }

    addGeofence() {
        this.dialogRef = this.dialog.open(AddGeofenceComponent, {
            data: {}
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.listStatusFilter = GeofenceStatus.STAGING;
                this.showResponse(result);
            }
        });
    }

    adjustBounds(data: any) {
        if(data.type.toLowerCase() === 'multipolygon') {
            data.coordinates.forEach(multipolygon => {
                multipolygon.forEach(polygon => {
                    polygon.forEach(coordinates => {
                        this.bonds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                    });
                });
            });
        } else if(data.type.toLowerCase() === 'polygon') {
            data.coordinates.forEach(polygon => {
                polygon.forEach(coordinates => {
                    this.bonds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                });
            });
        }
        this.map.fitBounds(this.bonds);
    }

    clearMapData() {
        this.bonds = new google.maps.LatLngBounds();
        this.map.setCenter({lat: 38.0, lng: -97});
        this.map.setZoom(5);
        this.clearGeofenceDataFromMap();
    }

    clearGeofenceDataFromMap() {
        this.map.data.setStyle({});
        this.map.data.forEach(feature => {
            this.map.data.remove(feature);
        });
    }

    selectAllGeofences(event: any) {
        if(event.checked) {
            this.selectedGeofences = this.dataSource.filteredData;
        } else {
            this.selectedGeofences = [];
        }
    }

    selectGeofence(geofence: any, event:any) {
        const index = this.selectedGeofences.findIndex(gf => gf.geofenceId === geofence.geofenceId);
        if(event.checked && index === -1) {
            this.selectedGeofences.push(geofence);
        } else if(!event.checked && index !== -1)  {
            this.selectedGeofences.splice(index, 1);
        }
    }

    isGeofenceSelected(geofenceId: string) {
        const index = this.selectedGeofences.findIndex(gf => gf.geofenceId === geofenceId);
        return index !== -1
    }

    onGeofenceSelect(geofence: any) {
        if(this.selectedGeofenceId === geofence.geofenceId) {
            this.clearMapData();
            this.selectedGeofence = undefined;
            this.selectedGeofenceId = undefined;
            return;
        }
        this.selectedGeofence = undefined;      //This line is required for change event to trigger
        this.selectedGeofenceId = undefined;    //This line is required for change event to trigger
        this.selectedGeofenceId = geofence.geofenceId;

        this.geofenceService.getGeofenceData(geofence.gId).subscribe({
            next: data => {
                this.selectedGeofence = data;
                this.displayGeofenceOnMap();
            },
            error: error => {
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            },
            complete: () => { }
        });
    }

    downloadGeofence(geofence: any) {
        this.geofenceService.getGeofenceData(geofence.gId).subscribe({
            next: data => {
                let geoJsonObject: Object = {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "properties": data.properties,
                            "geometry": data.exact_polygon
                        }
                    ]
                };
                let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(geoJsonObject, null, 4));
                let link = document.createElement('a');
                link.href = dataStr;
                link.download = geofence.geofenceId + ".geojson";
                document.body.appendChild(link); // required for firefox
                link.click();
                link.remove();
            },
            error: error => {
                this.sharedService.showAlert(Alerts.ERROR, "Unable to download geofence data");
            },
            complete: () => { }
        });
    }

    markGeofenceAsPending(geofenceId: string) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Mark geofence ' + geofenceId + ' as pending',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Mark Pending',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                let selectedGeofenceIds: Array<string> = [geofenceId];
                this.markAsPending(selectedGeofenceIds);
            }
        });
    }

    markGeofencesAsPending() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Mark selected geofence(s) as pending',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Mark Pending',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                let selectedGeofenceIds: Array<string> = [];
                this.selectedGeofences.forEach(geofence => {
                    selectedGeofenceIds.push(geofence.geofenceId);
                });
                this.markAsPending(selectedGeofenceIds);
            }
        });
    }

    // markAllAsPending() {
    //     let selectedGeofenceIds: Array<string> = [];
    //     this.markAsPending(selectedGeofenceIds);
    // }

    markAsPending(geofenceIds: Array<string>) {
        let payload: any = {
            "geofenceIds": geofenceIds
        };
        Swal.fire({
            title: 'Please Wait...',
            text: 'Marking geofence(s) as pending',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        this.geofenceService.markGeofencesAsPending(payload).subscribe({
            next: data => {
                Swal.close();
                this.showResponse(data);
            },
            error: error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            },
            complete: () => { }
        });
    }

    showResponse(resultList: any[]) {
        this.dialogRef = this.dialog.open(ShowResultComponent, {
            data: resultList
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedGeofence = undefined;      //This line is required for change event to trigger
                this.selectedGeofenceId = undefined; 
                this.clearMapData();
                this.getGeofenceList();
            }
        });
    }

    markGeofenceAsStaging(geofenceId: string) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Mark geofence ' + geofenceId + ' as staging',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Mark Staging',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                let selectedGeofenceIds: Array<string> = [geofenceId];
                this.markAsStaging(selectedGeofenceIds);
            }
        });
    }

    markAsStaging(geofenceIds: Array<string>) {
        let payload: any = {
            "geofenceIds": geofenceIds
        };
        Swal.fire({
            title: 'Please Wait...',
            text: 'Marking geofence(s) as staging',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        this.geofenceService.markGeofencesAsStaging(payload).subscribe({
            next: data => {
                Swal.close();
                this.showResponse(data);
            },
            error: error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            },
            complete: () => {}
    });
    }

    updateGeofence(geofence: any) {
        let selectedGeofences: Array<any> = [geofence];
        this.updateGeofenceList(selectedGeofences);
    }

    updateGeofences() {
        this.updateGeofenceList(this.selectedGeofences);
    }

    updateGeofenceList(geofences: Array<any>) {
        this.dialogRef = this.dialog.open(UpdateGeofenceComponent, {
            data: {
                "geofences": geofences,
                "status": this.listStatusFilter
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.clearMapData();
                if (this.listStatusFilter === GeofenceStatus.VERIFIED) {
                    this.listStatusFilter = GeofenceStatus.PENDING;
                    this.statusFilters = [GeofenceStatus.PENDING, GeofenceStatus.VERIFIED];
                }
                this.getGeofenceList();
            }
        });
    }

    verifyGeofence(geofenceId: string) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Verify geofence ' + geofenceId,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Verify',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                let selectedGeofenceIds: Array<string> = [geofenceId];
                this.verifyGeofenceList(selectedGeofenceIds);
            }
        });
    }    

    verifySelectedGeofences() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Verify selected geofence(s)',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Verify',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                let selectedGeofenceIds: Array<string> = [];
                this.selectedGeofences.forEach(geofence => {
                    selectedGeofenceIds.push(geofence.geofenceId);
                });
                this.verifyGeofenceList(selectedGeofenceIds);
            }
        });
    }

    verifyAll() {
        let selectedGeofenceIds: Array<string> = [];
        this.verifyGeofenceList(selectedGeofenceIds);
    }

    verifyGeofenceList(geofenceIds: Array<string>) {
        let payload: any = {
            "geofenceIds": geofenceIds
        };
        Swal.fire({
            title: 'Please Wait...',
            text: 'Verifying geofence(s)',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        this.geofenceService.verifyGeofenceList(payload).subscribe({
            next: data => {
                Swal.close();
                this.showResponse(data);
            },
            error: error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            },
            complete: () => {}
    });
    }

    deleteGeofence(geofence: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete geofence ' + geofence.geofenceId,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            Swal.fire({
                title: 'Please Wait...',
                text: 'Deleting geofence ' + geofence.geofenceId,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            if (result.value) {
                let payload = {
                    "geofenceId": geofence.geofenceId,
                    "status": this.listStatusFilter
                }
                this.geofenceService.deleteGeofence(payload).subscribe({
                    next: data => {
                        Swal.close();
                        this.sharedService.showAlert(Alerts.SUCCESS, "Geofence has been deleted successfully");
                        this.clearMapData();
                        if (this.listStatusFilter === GeofenceStatus.VERIFIED) {
                            this.listStatusFilter = GeofenceStatus.PENDING;
                            this.statusFilters = [GeofenceStatus.PENDING, GeofenceStatus.VERIFIED];
                        }
                        this.getGeofenceList();
                    },
                    error: error => {
                        Swal.close();
                        this.sharedService.showAlert(Alerts.ERROR, error.error.message);
                    },
                    complete: () => {}
            });
            } else {
                Swal.close();
            }
        });
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }
    
    onStatusChange(e) {
        this.clearMapData();
        this.selectedGeofence = undefined;
        this.selectedGeofenceId = undefined;
        this.getGeofenceList();
    }

    onListStatusChange(e) {
        if (this.listStatusFilter === GeofenceStatus.PENDING) {
            this.statusFilters = [GeofenceStatus.PENDING, GeofenceStatus.VERIFIED];
        } else {
            this.statusFilters = []
        }
        this.clearMapData();
        this.selectedGeofence = undefined;
        this.selectedGeofenceId = undefined;
        this.getGeofenceList();
    }

}
