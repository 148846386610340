import { UsageEnum } from '../enums/usageEnum';
import { DisplayAttributeTo } from './displayAttributeTo';
import { TemplateFileTo } from './templateFileTo';
import { TextblockFileTo } from './textblockFileTo';

export class SearchTypeTo {
    name: string;
    entitlement: string;
    responseType: string;
    responseSchemaFile: TemplateFileTo;
    responseSchemaBaseFilePath: string;
    uiTemplateFile: TemplateFileTo;
    uiTemplate: string;
    locationUsage: UsageEnum;
    telephoneUsage: UsageEnum;
    addressUsage: UsageEnum;
    incidentUsage: UsageEnum;
    searchTypeUsage: UsageEnum;
    enabled: Boolean;
    automatic: Boolean;
    displayAttributeList: DisplayAttributeTo[];
    attributePathList: any;
    templateFileList: TemplateFileTo[];
    textblocksFileList: TextblockFileTo[];
    dynamicDataProducer: Boolean;
    callAssociated: Boolean;
    handleDelayedResponses: Boolean;

    constructor(object?: any) {
        if (object) {
            this.name = object.name;
            this.entitlement = object.entitlement;
            this.responseType = object.responseType;
            this.responseSchemaFile = new TemplateFileTo(object.responseSchemaFile);
            this.responseSchemaBaseFilePath = object.responseSchemaBaseFilePath;
            this.uiTemplateFile =  new TemplateFileTo(object.uiTemplateFile);
            this.locationUsage = object.locationUsage;
            this.telephoneUsage = object.telephoneUsage;
            this.addressUsage = object.addressUsage;
            this.incidentUsage = object.incidentUsage;
            this.searchTypeUsage = object.searchTypeUsage;
            this.enabled = object.enabled;
            this.automatic = object.automatic;
            this.displayAttributeList = [];
            object.displayAttributeList?.forEach(element => {
                this.displayAttributeList.push(new DisplayAttributeTo(element));
            });
            this.attributePathList = {};
            if(object.attributePathList) {  
                Object.keys(object.attributePathList).forEach(
                    k => {
                        this.attributePathList[k] = object.attributePathList[k];
                    }
                );
            }     
            this.templateFileList = [];
            object.templateFileList?.forEach(element => {
                this.templateFileList.push(new TemplateFileTo(element));
            });
            this.textblocksFileList = [];
            object.textblocksFileList?.forEach(element => {
                this.textblocksFileList.push(new TextblockFileTo(element));
            });
            this.dynamicDataProducer = object.dynamicDataProducer;
            this.callAssociated = object.callAssociated;
            this.handleDelayedResponses = object.handleDelayedResponses;
        }
    }
}