// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card {
  width: 650px !important;
}

::ng-deep .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1254901961) !important;
}

.example-container {
  margin: 7px 7px 7px 7px;
}

.example-container > * {
  width: 100%;
}

.card {
  width: 28rem;
}

.error-icon {
  color: red;
  font-size: 20px;
}

.tab-body-content {
  max-height: 625px;
  overflow: auto;
}

::ng-deep .mdc-label {
  margin-bottom: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/search-types/update-search-type/update-search-type.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,+DAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,6BAAA;AACF","sourcesContent":[".mat-mdc-card {\n  width: 650px !important;\n}\n\n::ng-deep .mat-mdc-tab-header { \n  border-bottom: 1px solid #00000020 !important;\n}\n\n.example-container {\n  margin: 7px 7px 7px 7px;\n}\n\n.example-container > * {\n  width: 100%;\n}\n\n.card {\n  width: 28rem\n}\n\n.error-icon {\n  color: red;\n  font-size: 20px;\n}\n\n.tab-body-content {\n  max-height: 625px;\n  overflow: auto;\n}\n\n::ng-deep .mdc-label {\n  margin-bottom: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
