import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {SearchTypesService } from '../search-type.service';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { SearchTypeBase } from '../search-type-base';
import { saveAs } from 'file-saver';
import { TemplateFileTo } from 'src/app/shared/models/templateFileTo';
import { TextBlocksService } from 'src/app/text-blocks/text-blocks.service';
import { TextblockFileTo } from 'src/app/shared/models/textblockFileTo';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-update-search-type',
    templateUrl: './update-search-type.component.html',
    styleUrls: ['./update-search-type.component.scss']
})
export class UpdateSearchTypeComponent extends SearchTypeBase implements OnInit{
    options: UntypedFormGroup;
    loading = false;
    count = 1;
    resetData: SearchTypeTo;

    constructor(@Inject(MAT_DIALOG_DATA) private data: SearchTypeTo,
                private fb: UntypedFormBuilder, 
                public dialogRef: MatDialogRef<UpdateSearchTypeComponent>,
                private searchTypesService: SearchTypesService, 
                protected sharedService: SharedService,
                private organizationService: OrganizationsService,
                private textBlocksService: TextBlocksService) {
        super(sharedService);
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
        this.organizationService.getAllEntitlementDef().subscribe(
            data => {
                data.forEach(entitlement => this.entitlementList.push(entitlement));
                this.entitlementList.sort((a, b) => a.displayName < b.displayName ? -1 : 1);
            }
        );
        this.getAllTextBlocks();
        this.searchTypeData = new SearchTypeTo(this.data);
        this.resetData = new SearchTypeTo(this.data);
        this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
    }

    getAllTextBlocks() {
        this.textBlocksService.getAllTextBlocks().subscribe({
            next: data => {
                this.textBlockList = data;
            },
            error: error => {
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error, null, 'Unable to retrieve Text Blocks');
            },
            complete: () => { }
        });
    }

    verifyAndUpdateSearchType() {
        if(!this.isValidData(false)) {
            return;
        }
        this.loading = true;
        this.updateSearchType();
    }

    updateSearchType() {
        if (this.isSearchTypeDetailsModified() || this.isTemplatesModified() || this.isTextblocksModified() ||
            JSON.stringify(this.resetData.displayAttributeList) !== JSON.stringify(this.searchTypeData.displayAttributeList) ||
            JSON.stringify(this.resetData.attributePathList) !== JSON.stringify(this.searchTypeData.attributePathList)) {
            this.loading = true;
            this.searchTypeData.templateFileList.forEach(element => {
                element.destinationFilename = element.sourceFilename;
            });
            var fd = new FormData();
            fd.append('SearchTypeTo', JSON.stringify(this.searchTypeData));
            if (this.searchTypeData.responseSchemaFile.sourceFile) {
                fd.append(this.searchTypeData.responseSchemaFile.name,
                    this.searchTypeData.responseSchemaFile.sourceFile,
                    this.searchTypeData.responseSchemaFile.destinationFilename);
            }
            if (this.searchTypeData.uiTemplateFile.sourceFile) {
                fd.append(this.searchTypeData.uiTemplateFile.name,
                    this.searchTypeData.uiTemplateFile.sourceFile,
                    this.searchTypeData.uiTemplateFile.destinationFilename);
            }
            this.searchTypeData.templateFileList.forEach(element => {
                if (element.sourceFile && element.name && element.destinationFilename) {
                    fd.append(element.name, element.sourceFile, element.destinationFilename);
                }
            });
            this.searchTypeData.textblocksFileList.forEach(element => {
                if (element.sourceFile && element.name && element.destinationFilename) {
                    fd.append(element.name, element.sourceFile, element.destinationFilename);
                }
            });
            this.searchTypesService.updateSearchType(fd).subscribe({
                next: data => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'SearchType Updated successfully');
                    this.dialogRef.close(data);
                },
                error: error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error,
                        [{
                            "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                            "msg": error.error[Object.keys(error.error)[0]]
                        }],
                        "Unable to update SearchType");
                },
                complete: () => { }
            });
        } else {
            this.loading = false;
            this.sharedService.showAlert(Alerts.ERROR, 'Please modify the details to update');
            this.markFormControlsAsTouched();
        }
    }

    isSearchTypeDetailsModified(): boolean {
        return this.resetData.entitlement !== this.searchTypeData.entitlement ||
                this.resetData.responseType !== this.searchTypeData.responseType || 
                JSON.stringify(this.resetData.responseSchemaFile) !== JSON.stringify(this.searchTypeData.responseSchemaFile) ||
                this.resetData.responseSchemaBaseFilePath !== this.searchTypeData.responseSchemaBaseFilePath ||
                JSON.stringify(this.resetData.uiTemplateFile) !== JSON.stringify(this.searchTypeData.uiTemplateFile) ||
                this.resetData.locationUsage !== this.searchTypeData.locationUsage ||
                this.resetData.telephoneUsage !== this.searchTypeData.telephoneUsage ||
                this.resetData.addressUsage !== this.searchTypeData.addressUsage ||
                this.resetData.incidentUsage !== this.searchTypeData.incidentUsage ||
                this.resetData.searchTypeUsage !== this.searchTypeData.searchTypeUsage ||
                this.resetData.enabled !== this.searchTypeData.enabled ||
                this.resetData.automatic !== this.searchTypeData.automatic ||
                this.resetData.dynamicDataProducer !== this.searchTypeData.dynamicDataProducer ||
                this.resetData.callAssociated !== this.searchTypeData.callAssociated ||
                this.resetData.handleDelayedResponses !== this.searchTypeData.handleDelayedResponses;
    }

    isTemplatesModified(): boolean {
        return JSON.stringify(this.searchTypeData.templateFileList) !== JSON.stringify(this.resetData.templateFileList);
    }

    isTextblocksModified(): boolean {
        return JSON.stringify(this.searchTypeData.textblocksFileList) !== JSON.stringify(this.resetData.textblocksFileList);
    }

    resetSearchType() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.searchTypeData = new SearchTypeTo(this.resetData);
                    this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
                    this.fieldcheck = false;
                }
            });
        } else {
            this.searchTypeData = new SearchTypeTo(this.resetData);
            this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
        }
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || !this.fieldcheck) {
            this.fieldcheck = true;
        }
        
    }


    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Close the update SearchType dialog box?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }

    }

    isDownloadable(templateFile: TemplateFileTo) {
        if ((templateFile.sourceFilename!='' && templateFile.sourceFilename!=undefined && templateFile.sourceFilename!=null) 
            && (templateFile.sourceFile==null || templateFile.sourceFile==undefined)) {
            return true;
        }
        return false
    }


    downloadResponseSchema() {
        this.downloadFile('responseSchema', this.searchTypeData.responseSchemaFile.destinationFilename, this.searchTypeData.responseSchemaFile.sourceFilename);
    }

    downloadUITemplate() {
        this.downloadFile('uiTemplate', this.searchTypeData.uiTemplateFile.destinationFilename, this.searchTypeData.uiTemplateFile.sourceFilename);
    }

    downloadTemplate(templateFile: TemplateFileTo) {
        this.downloadFile('templates', templateFile.destinationFilename, templateFile.sourceFilename);
    }

    downloadTextblock(textblockFile: TextblockFileTo) {
        this.downloadFile('textblocks', textblockFile.destinationFilename, textblockFile.sourceFilename);
    }

    downloadFile(type, filename, saveAsFilename) {
        this.searchTypesService
                .downloadSearchTypeFile(this.searchTypeData.name, type, filename)
                .subscribe(blob => saveAs(blob, saveAsFilename));
    }

}